import React, { useContext, useEffect, useState } from "react";
import MenuButton from "../MenuButton/MenuButton";
import { BsPersonCheck } from "react-icons/bs";
import { IoMdMore } from "react-icons/io";
import { HiSpeakerphone } from "react-icons/hi";
import "react-datepicker/dist/react-datepicker.css";

import {
  BsArrowDownRightCircleFill,
  BsArrowUpRightCircleFill,
} from "react-icons/bs";
import { useFormik } from "formik";
import UserServices from "../../UserServices/UserServices";
import { NoteState } from "../../context/notes/NoteState";
import { BiExport } from "react-icons/bi";
import moment from "moment";
import useTable from "../../hooks/useTable";
import useSortableData from "../../hooks/useSortableData";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
// Date picker
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
// import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

export default function Employeeattendance() {
  const [loading, setLoading] = useState(false);
  const [myBranches, setMyBranches] = useState([]);
  const { state } = useContext(NoteState);
  const { branch_id } = state;
  const [branchId, setBranchId] = useState(''); 
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const [exportLink, setExportLink] = useState("");
  const [option, setOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState("25");
  const [radioOption, setRadioOption] = useState("Detail");
  const [myData, setMyData] = useState([]);
  const [names, setNames] = useState([]);
  const [count, setCount] = useState("");
  const [onTime, setOnTime] = useState("");
  const [late, setLate] = useState("");
  const [absent, setAbsent] = useState("");
  const [leave, setLeave] = useState("");

  const [activeFilter, setActiveFilter] = useState("");
  const [myDepartment, setMyDepartment] = useState([]);
  const [attendanceStatus, setAttendanceStatus] = useState("");

  console.log('checking id ',branchId);
  

  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-CA");
  let setDate = moment(defaultValue).format("YYYY-MM-DD");

  const branches = async () => {
    try {
      const responce = await UserServices.branchName();
      const res = responce.data.data;
      const results = [];

      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyBranches([{ name: "Select Branches", id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  const department = async () => {
    try {
      const responce = await UserServices.departmentName();
      const res = responce.data.data;
      const results = [];
      res.map((value) => {
        results.push({
          id: value.id,
          name: value.name,
        });
        return null;
      });
      setMyDepartment([...results]);
    } catch (err) {
      console.log(err);
      setMyDepartment([]);
    }
  };
  const filterReport = async (values, val, attendanceStatus, page, limit) => {
    setLoading(true);
    let staff_id = document.getElementById("answerInput-hidden").value;
    try {
      const response = await UserServices.getFilterEmployeeAttendence(
        branchId,
        staff_id,
        values,
        val,
        attendanceStatus,
        limit,
        page
      );
      let myDataarr;
      if (response.status === 200) {
        myDataarr = response.data.data.data;
        const res = myDataarr;
        const results = [];
        res.map((value) => {
          results.push({
            member_id: value.member_id,
            first_name: value.attendee.first_name,
            last_name: value.attendee.last_name,
            checkin_time_12h: value.checkin_time_12h,
            date: value.date,
            checkout_time_12h: value.checkout_time_12h,
            gender: value.gender,
            number: value.number,
            verified_by: value.verified_by,
            Designation: value.Designation,
            id: value.attendee.id,
            card_number: value.card_number,
            working_hours: value.working_hours,
            branch_name: value.branch_id === 1 ? "G-13" : value.branch_id === 15 ? "F-11" : value.branch_id,
            attendance_status: value.attendance_status,
            attendee: value.attendee,
            designation: value.designation,
            start_time: moment(value.start_time, "HH:mm").isValid() ? moment(value.start_time, "HH:mm").format("hh:mm A") : "Not",
            end_time: moment(value.end_time, "HH:mm").isValid() ? moment(value.end_time, "HH:mm").format("hh:mm A") : "Assigned",
            checkinBackgroundColor: getCheckinBackgroundColor(value), // Call check-in function
            checkoutBackgroundColor: getCheckoutBackgroundColor(value), // Call checkout function
          });
          return null;
        });
        setMyData([...results]);
        setLimit(response.data.data.per_page);
        setTotalResults(response.data.totalRecord);
        setTotalPages(response.data.totalPages);
        setCurrentPage(response.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      setExportLink();
      setTotalResults("0");
      setTotalPages("1");
      setMyData([]);
      setLoading(false);

      if (err.response.status === 401) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request time Out!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "LogIn Again!",
        });
      } else if (err.response.status === 404) {
        // Handle 404 error if needed
      }
    }
  };
  const getCheckinBackgroundColor = (value) => {
    const dayOfWeek = moment(value.date).format("dddd");
    const dutyDay = value.attendee.duty_hours.find(
      (duty) => duty.day.trim() === dayOfWeek.trim()
    );

    if (dutyDay) {
      const checkinTime = moment(value.checkin_time_24h, "HH:mm:ss");
      const dutyStartTime = moment(dutyDay.start_time, "HH:mm").add(
        15,
        "minutes"
      );

      if (checkinTime.isAfter(dutyStartTime)) {
        return "red"; // Check-in time is after duty start time
      }
    }

    return "black"; // Default color
  };

  const getCheckoutBackgroundColor = (value) => {
    const dayOfWeek = moment(value.date).format("dddd");
    const dutyDay = value.attendee.duty_hours.find(
      (duty) => duty.day.trim() === dayOfWeek.trim()
    );

    if (dutyDay) {
      const checkoutTime = moment(value.checkout_time_24h, "HH:mm:ss");
      const dutyEndTime = moment(dutyDay.end_time, "HH:mm");

      if (checkoutTime.isBefore(dutyEndTime)) {
        return "red"; // Checkout time is before duty end time
      }
    }

    return "black"; // Default color
  };

  const fetchData = async (page, limit, values, staff_id) => {
    setLoading(true);
    try {
      const response = await UserServices.getEmployeeAttendence(
        branchId,
        values,
        staff_id,
        limit,
        page
      );

      if (response.status === 200) {
        setExportLink(
          `${process.env.REACT_APP_API_Link}/attendance/export-staff?category=2&request_status=all&type=Staff&branchId=${branchId}&member_id=${staff_id}&start_date=${values.sdate}&end_date=${values.edate}`
        );

        const myDataarr = response.data.data.data;
        const results = myDataarr.map((value) => ({
          member_id: value.member_id,
          first_name: value.attendee.first_name,
          last_name: value.attendee.last_name,
          checkin_time_12h: value.checkin_time_12h,
          date: value.date,
          checkout_time_12h: value.checkout_time_12h,
          gender: value.gender,
          number: value.number,
          verified_by: value.verified_by,
          Designation: value.Designation,
          id: value.attendee.id,
          card_number: value.card_number,
          working_hours: value.working_hours,
          //adeel - future changes
          branch_name: value.branch_id === 1 ? "G-13" : value.branch_id === 15 ? "F-11" : value.branch_id,
          attendance_status: value.attendance_status,
          attendee: value.attendee,
          designation: value.designation,
          start_time: moment(value.start_time, "HH:mm").isValid() ? moment(value.start_time, "HH:mm").format("hh:mm A") : "Not",
          end_time: moment(value.end_time, "HH:mm").isValid() ? moment(value.end_time, "HH:mm").format("hh:mm A") : "Assigned",
          checkinBackgroundColor: getCheckinBackgroundColor(value), // Call check-in function
          checkoutBackgroundColor: getCheckoutBackgroundColor(value), // Call checkout function
        }));

        setMyData([...results]);
        setLimit(response.data.data.per_page);
        setTotalResults(response.data.totalRecord);
        setTotalPages(response.data.totalPages);
        setCurrentPage(response.data.data.current_page);
        setLoading(false);
      }
    } catch (err) {
      setExportLink();
      setTotalResults("0");
      setTotalPages("1");
      setMyData([]);
      setLoading(false);
      if (err.response && err.response.status === 401) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Request time Out!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "LogIn Again!",
        });
      }
    }
  };

  const generateReport = async (values) => {
    if (Date.parse(values.sdate) <= Date.parse(values.edate)) {
      let e = document.getElementById("answerInput");
      let options = document.querySelectorAll(
        `#${e.getAttribute("list")} option`
      );
      let hiddenInput = document.getElementById(
        `${e.getAttribute("id")}-hidden`
      );
      let inputValue = e.value;

      for (let i = 0; i < options.length; i++) {
        let option = options[i];
        if (option.innerText === inputValue) {
          hiddenInput.value = option.getAttribute("data-value");
          break;
        } else {
          hiddenInput.value = "-1";
        }
      }

      let staff_id = document.getElementById("answerInput-hidden").value;
      await fetchData(1, limit, values, staff_id);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "End date should be greater than start date!",
      });
    }
  };
  const handlePagination = async (
    page,
    limit,
    filterValues,
    activeFilter,
    attendanceStatus
  ) => {
    setCurrentPage(page);
    // Check if filter values are present
    if (filterValues) {
      await filterReport(
        filterValues,
        activeFilter,
        attendanceStatus,
        page,
        limit
      );
    } else {
      let staff_id = document.getElementById("answerInput-hidden").value;
      await fetchData(page, limit, values, staff_id);
    }
  };

  const changePagination = async (page) => {
    await handlePagination(page, limit, values, activeFilter, attendanceStatus);
  };

  const changeLimit = async (newLimit) => {
    await handlePagination(1, newLimit, values, activeFilter, attendanceStatus);
  };
  const staffNames = async () => {
    try {
      const responce = await UserServices.staffName(branchId);
      const res = responce.data.data;
      const results = [];
      res.map((values) => {
        results.push({
          id: values.id,
          name: values.first_name + " " + values.last_name,
        });
        return null;
      });
      setNames([{ id: "" }, ...results]);
    } catch (err) {
      console.log(err);
    }
  };
  // Define a debounce function
  function debounce(func, delay) {
    let timer;
    return function (...args) {
      const context = this;
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  }

  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  // Debounced function for generating report
  const debouncedGenerateReport = debounce((values) => {
    if (!isGeneratingReport) {
      setIsGeneratingReport(true);
      generateReport(values)
        .then(() => setIsGeneratingReport(false))
        .catch((error) => {
          setIsGeneratingReport(false);
          // Handle error if needed
        });
    }
  }, 500); // Adjust the delay as needed, here it's set to 500ms
  const staffCounter = async () => {
    try {
      const responce = await UserServices.staffCount(branchId);
      if (responce.status === 200) {
        setCount(responce.data.data);
      }
    } catch (err) {}
  };
  const AttendanceSummary = async () => {
    try {
      const responce = await UserServices.staffAttendanceCount(branchId);
      if (responce.status === 200) {
        setOnTime(responce.data.on_time);
        setLate(responce.data.late);
        setAbsent(responce.data.absent);
        setLeave(responce.data.leave);
        
      }
    } catch (err) {}
  };
  useEffect(() => {
    branches();
    staffNames();
    generateReport(values);
    staffCounter();
    AttendanceSummary();
    department();
  }, [branchId]);
  const initialValues = {
    branch_id: branchId,
    sdate: setDate,
    edate: setDate,
  };
  const { values, handleBlur, setFieldValue, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: "",
      onSubmit: (values) => {
        generateReport(values);
      },
    });

  const [dateRange, setDateRange] = useState([setDate, setDate]);
  const [startDate, endDate] = dateRange;

  const getFontWeight = (backgroundColor) => {
    return backgroundColor !== "black" ? "bold" : "normal";
  };
  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table
          className="table table-striped table-borderless table-style-1 align-middle"
          id="report-POS"
          data-caption="New SPT Bookings"
        >
          <thead>
            <tr>
              <th scope="col" className="no_sort">
                Sr#
              </th>
              <th scope="col" className="no_sort">
                Employee ID
              </th>
              <th
                scope="col"
                onClick={() => requestSort("first_name")}
                className={getClassNamesFor("first_name")}
              >
                Employee Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("start_time")}
                className={getClassNamesFor("start_time")}
              >
                Duty Hours
              </th>
              <th
                scope="col"
                onClick={() => requestSort("branch_name")}
                className={getClassNamesFor("branch_name")}
              >
                Branch
              </th>
              <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Date
              </th>
              <th scope="col" className="no_sort">
                Day
              </th>
              <th scope="col" className="no_sort">
                Clock In
              </th>
              <th scope="col" className="no_sort">
                Clock Out
              </th>

              <th
                scope="col"
                onClick={() => requestSort("attendance_status")}
                className={getClassNamesFor("attendance_status")}
              >
                Attendance Status
              </th>
              <th scope="col" className="no_sort">
                Late
              </th>
              <th scope="col" className="working_hours">
                Working Hours
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.attendee.uid}</td>
                  <td>
                    <div className="d-flex">
                      <div className="attende-img">
                        <img
                          src={
                            el.attendee.image
                              ? el.attendee.image
                              : "https://i.pinimg.com/736x/09/21/fc/0921fc87aa989330b8d403014bf4f340.jpg"
                          }
                          alt=""
                        />
                      </div>
                      <div className="ms-2">
                        <div className="mt-1">
                          <Link to={"/staff-profile/" + el.id}>
                            {el.first_name} {el.last_name}
                          </Link>
                        </div>
                        <div>{el.designation}</div>
                      </div>
                    </div>
                  </td>
                  <td> ({el.start_time + " - " + el.end_time})</td>
                  <td>{el.branch_name}</td>
                  <td>{moment(el.date).format("DD-MM-YYYY")}</td>
                  <td>{moment(el.date).format("dddd")}</td>
                  <td
                    style={{
                      color: el.checkinBackgroundColor,
                      fontWeight: getFontWeight(el.checkinBackgroundColor),
                    }}
                  >
                    {el.checkin_time_12h}
                  </td>
                  <td
                    style={{
                      color: el.checkoutBackgroundColor,
                      fontWeight: getFontWeight(el.checkoutBackgroundColor),
                    }}
                  >
                    {el.checkout_time_12h}
                  </td>
                  <td>{el.attendance_status}</td>
                  <td></td>
                  <td>{el.working_hours}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="14" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </>
    );
  };
  const handleFilterClick = (filterId) => {
    setActiveFilter(filterId); // Set active filter based on button clicked
    filterReport(values, filterId, attendanceStatus, 1, 25); // Call your filter function here
  };
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <MenuButton />
          <div className="card">
            <div className="card-header py-3 d-flex">
              <h5 className="mb-0 font-20 text-black">Attendance</h5>
              <div className="ms-auto" style={{ cursor: "pointer" }}>
                <BsPersonCheck className="fs-3 text-danger" />
                <IoMdMore className="fs-3 text-secondary" />
              </div>
            </div>
            <div className="card-body">
              <div className="container-fluid">
                <div className="row attendance-summary-head">
                  <div className="col-md-3 d-flex p-0">
                    <div className="new-attendance-head d-flex">
                      <div className="head-icon">
                        <HiSpeakerphone className="fs-1" />
                      </div>
                      <div className="head-data ms-3">
                        <div className="d-flex align-items-center">
                          <h4 className="first-heading mt-1">Today</h4>
                          <span className="text-danger ms-2 fs-5">
                            {moment(setDate).format("DD-MM-YYYY")}
                          </span>
                        </div>
                        <div className="first-heading-p">
                          <p>This show daily data in real time.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9 d-flex p-0">
                    <div className="new-attendance-head2">
                      <div className="second-head">
                        <div className="fw-bold mb-2 fs-6">Total Employees</div>
                        <div className="d-flex justify-content-between">
                          <span className="fs-6">{count}</span>
                          <div>
                            <BsArrowDownRightCircleFill className="fs-5 text-danger" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="new-attendance-head2">
                      <div className="second-head">
                        <div className="fw-bold mb-2 fs-6">On Time</div>
                        <div className="d-flex justify-content-between">
                          <span className="fs-6">{onTime}</span>
                          <div>
                            <BsArrowUpRightCircleFill className="fs-5 text-success" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="new-attendance-head2">
                      <div className="second-head">
                        <div className="fw-bold mb-2 fs-6">Late In</div>
                        <div className="d-flex justify-content-between">
                          <span className="fs-6">{late}</span>
                          <div>
                            <BsArrowDownRightCircleFill className="fs-5 text-danger" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="new-attendance-head2">
                      <div className="second-head">
                        <div className="fw-bold mb-2 fs-6">Absent</div>
                        <div className="d-flex justify-content-between">
                          <span className="fs-6">{absent}</span>
                          <div>
                            <BsArrowDownRightCircleFill className="fs-5 text-danger" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="new-attendance-head2 border-0">
                      <div className="second-head">
                        <div className="fw-bold mb-2 fs-6">On Leave</div>
                        <div className="d-flex justify-content-between">
                          <span className="fs-6">{leave}</span>
                          <div>
                            <BsArrowUpRightCircleFill className="fs-5 text-success" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 line-sepration mt-4"></div>
                <form className="" onSubmit={handleSubmit}>
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <div className="fw-bold fs-5 attendance-heading">
                        Employee Attendance
                      </div>
                      <p className="text-secondary attendance-heading-p mt-2">
                        Keep track employee attendance on a daily basis.
                      </p>
                    </div>
                    <div className="col-md-8 d-flex justify-content-end">
                      <div className="d-flex">
                      <div className="">
                          <label className="form-label">Branch</label>
                          {myBranches ? (
                            <select
                              className="form-select"
                              name="branch_id"
                              disabled={branch_id ? true : false}
                              value={values.branch_id || ""}
                              onChange={(e) => {
                                handleChange(e);
                                const selectedBranch = e.target.value;
                                if (selectedBranch === "1") {
                                  setBranchId(1);
                                } else if (selectedBranch === "15") {
                                  setBranchId(15);
                                }
                                else if (selectedBranch === "") {
                                  setBranchId("");
                                }
                              }}
                              onBlur={handleBlur}
                              style={{
                                borderRadius: "5px",
                                maxWidth: "150px",
                                maxHeight: "58px",
                              }}
                            >
                              {myBranches.map((el, index) => {
                                return (
                                  <option key={index} value={el.id}>
                                    {el.name}
                                  </option>
                                );
                              })}
                            </select>
                          ) : null}
                        </div>
                        <div className="form-group custom-group ms-3">
                          <label className="form-label">Date</label>
                          <div className="date-range-with">
                            <DatePicker
                              selectsRange={true}
                              startDate={startDate}
                              endDate={endDate}
                              placeholderText="Select Start & End date"
                              onChange={(update) => {
                                setDateRange(update);
                                if (update[0] && update[1]) {
                                  generateReport({
                                    ...values,
                                    sdate: moment(update[0]).format(
                                      "YYYY-MM-DD"
                                    ),
                                    edate: moment(update[1]).format(
                                      "YYYY-MM-DD"
                                    ),
                                  });

                                  setFieldValue(
                                    "sdate",
                                    moment(update[0]).format("YYYY-MM-DD")
                                  );
                                  setFieldValue(
                                    "edate",
                                    moment(update[1]).format("YYYY-MM-DD")
                                  );
                                }
                                setAttendanceStatus("");
                              }}
                              isClearable={false}
                            />
                          </div>
                        </div>{" "}
                      </div>
                      <div className="ms-2 mt-4">
                        <div></div>
                        <a
                          href={exportLink}
                          rel="noreferrer"
                          className="ms-3 employe-attendance-export"
                        >
                          <BiExport className="fs-5 me-2" /> Export
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 line-sepration"></div>
                  <div className="row ">
                    <div className="col-12 d-flex flex-wrap">
                      {/* <div className="mt-3">
                        <button
                          className={`ms-3 employee-attendance-filter ${
                            activeFilter === ""
                              ? "employee-attendance-filter-active"
                              : ""
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            setActiveFilter("");
                            filterReport(values, "", attendanceStatus, 1, 25);
                          }}
                        >
                          All Employees
                        </button>
                      </div>
                      {myDepartment.length
                        ? myDepartment.map((el, index) => {
                            return (
                              <div className="ms-2 mt-3" key={index}>
                                <button
                                  className={`ms-3 employee-attendance-filter ${
                                    activeFilter === el.name
                                      ? "employee-attendance-filter-active"
                                      : ""
                                  }`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleFilterClick(el.id);
                                  }}
                                >
                                  {el.name}
                                </button>
                              </div>
                            );
                          })
                        : null} */}
                      <div className="mt-3">
                        <button
                          className={`ms-3 employee-attendance-filter ${
                            activeFilter === ""
                              ? "employee-attendance-filter-active"
                              : ""
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            setActiveFilter("");
                            filterReport(values, "", attendanceStatus, 1, 25);
                          }}
                        >
                          All Employees
                        </button>
                      </div>

                      {myDepartment.length
                        ? myDepartment.map((el, index) => (
                            <div className="ms-2 mt-3" key={index}>
                              <button
                                className={`ms-3 employee-attendance-filter ${
                                  activeFilter === el.id
                                    ? "employee-attendance-filter-active"
                                    : ""
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleFilterClick(el.id); // Call the handler function on click
                                }}
                              >
                                {el.name}
                              </button>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </form>
                <div className="col-12 line-sepration mt-3"></div>
                <div className="row">
                  <div className="col-6 d-flex  mt-2 mb-2 ">
                    <div className="form-group custom-group">
                      <input
                        style={{ width: "462px" }}
                        type="text"
                        className="form-control"
                        placeholder="Search Staff"
                        name="member_id"
                        autoComplete="off"
                        value={values.member_id || ""}
                        onChange={(e) => {
                          handleChange(e);
                          debouncedGenerateReport({
                            ...values,
                            member_id: e.target.value,
                          });
                        }}
                        onBlur={handleBlur}
                        list="members_name"
                        id="answerInput"
                      />
                      <input
                        type="hidden"
                        name="answer"
                        id="answerInput-hidden"
                      />

                      <datalist id="members_name">
                        {names.map((el, index) => {
                          return (
                            <option
                              key={index}
                              data-value={el.id}
                              value={el.name}
                            >
                              {el.name}
                            </option>
                          );
                        })}
                      </datalist>
                    </div>
                  </div>
                  <div className="col-md-2 mt-2 mb-2 ms-auto">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={attendanceStatus}
                        onChange={(e) => {
                          setAttendanceStatus(e.target.value);
                          filterReport(
                            values,
                            activeFilter,
                            e.target.value,
                            1,
                            25
                          ); // Trigger filter on change
                        }}
                      >
                        <option value="">All</option>
                        <option value="Present">Present</option>
                        <option value="Leave">Leave</option>
                        <option value="Absent">Absent</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-1 col-lg-1 mt-2 mb-2">
                    <div className="input-group">
                      <select
                        className="form-select"
                        value={limit}
                        onChange={(e) => changeLimit(e.target.value)}
                      >
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="table-responsive">
                      <Table data={myData} rowsPerPage={limit} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
